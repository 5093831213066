@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.border-dark {
    --tw-border-opacity: 1;
    border-color: rgba(136, 135, 135, var(--tw-border-opacity));
 }

 body{
    font-family: 'Helvetica', 'Arial', sans-serif;
  }
  
  .black-background {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.2);
    z-index: 5000;
  }

.tooltip {
  @apply invisible absolute;
}

.has-tooltip:hover .tooltip {
  @apply visible z-50;
}

/*fin tooltip*/

/*switch*/
.toggle-checkbox:checked {
  @apply: right-0 border-green-400;
  right: 0;
  border-color: #68D391;
}
.toggle-checkbox:checked + .toggle-label {
  @apply: bg-green-400;
  background-color: #68D391;
}
/*switch*/